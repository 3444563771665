import { Collapse } from "antd";
import React from "react";
import { DEFAULT_TITLE_HERO, MINT_PAGE_COMPONENT } from "../../../constants";
import { DownOutlined, RightOutlined } from "@ant-design/icons";

function ContractDetail({ mintPage }) {
  
  const heroComponent = mintPage.components.find(
    (component) =>
      component.componentType.name.toLowerCase() ===
      MINT_PAGE_COMPONENT.HERO.toLowerCase()
  );

  const text = heroComponent.text || DEFAULT_TITLE_HERO;

  const items = [
    {
      key: "1",
      label: <p style={{ color: "white", fontSize: 16 }}>View Details</p>,
      children: <p style={{ color: "white" }}>{text}</p>,
    },
    {
      key: "2",
      label: <p style={{ color: "white", fontSize: 16 }}>View on Platform</p>,
      children: <p style={{ color: "white" }}>{text}</p>,
    },
  ];

  return (
    <Collapse
      accordion
      items={items}
      expandIconPosition="end"
      ghost
      bordered
      expandIcon={({ isActive }) =>
        isActive ? (
          <DownOutlined style={{ color: "white" }} />
        ) : (
          <RightOutlined style={{ color: "white" }} />
        )
      }
    />
  );
}

export default ContractDetail;

import { Button, Col, Flex, message, Modal, Row, Tooltip } from "antd";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import VerifyPayment from "./VerifyPayment";
import { useAccount, useTransaction } from "../../../connect";
var xrpl = require("xrpl");

function PaymentMethodPopup({ smartContract }, ref) {
  const [isVisible, setIsVisible] = useState(false);
  const [payableAmount, setPayableAmount] = useState("");
  const [paymentReference, setPaymentReference] = useState("");
  const [targetChainPaymentAddress, setTargetChainPaymentAddress] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  const refVerifyPayment = useRef(null);
  const address = useAccount();
  const transaction = useTransaction();

  const onShow = ({
    payableAmount,
    paymentReference,
    targetChainPaymentAddress,
  }) => {
    setPayableAmount(payableAmount);
    setPaymentReference(paymentReference);
    setTargetChainPaymentAddress(targetChainPaymentAddress);
    setIsVisible(true);
  };

  const onCancel = () => {
    setIsVisible(false);
  };

  useImperativeHandle(ref, () => ({
    onShow,
    onCancel,
  }));

  const onOke = () => {
    setIsVisible(false);
  };

  console.log("address", address);

  const onPayment = async () => {
    try {
      setIsLoading(true);
      if (!transaction) return;
      const tx = {
        TransactionType: 'Payment',
        Account: address,
        Destination: targetChainPaymentAddress,
        Amount: xrpl.xrpToDrops(payableAmount),
        Memos: [
          {
            Memo: {
              MemoData: Buffer.from(paymentReference).toString("hex"),
            },
          },
        ],
      }
      const result = await transaction.signAndSubmit(tx)

      const transactionId = result?.tx_json?.hash;
  
      setIsLoading(false);
      onCancel();
      // open modal pay
      setTimeout(() => {
        if (refVerifyPayment) {
          refVerifyPayment.current?.onVerify({
            txId: transactionId,
          });
        }
      }, 300);
    } catch (error) {
      setIsLoading(false);
      message.error("Payment error");
      console.log("PaymentError", error);
    }
  };

  return (
    <Modal
      open={isVisible}
      onOk={onOke}
      onCancel={onCancel}
      footer={false}
      centered
      closeIcon={null}
    >
      <div className="gap-4">
        <Flex align="center" justify="space-between">
          <span style={{ fontSize: 24 }} className="fw-bold">
            Payment Method
          </span>
          <Button type="text" onClick={onCancel}>
            <img src="/common/ic_close.png" alt="" />
          </Button>
        </Flex>
        <div className="mt-4">
          <span style={{ fontSize: 16 }} className="fw-semibold">
            Details
          </span>
        </div>
        <Row gutter={16} className="mt-3">
          <Col span={12}>Payment amount</Col>
          <Col span={12} className="d-flex justify-content-end fw-semibold">
            {payableAmount} XRP
          </Col>
        </Row>
        <Row gutter={16} className="mt-3">
          <Col span={12}>Target Address</Col>
          <Col span={12} className="d-flex justify-content-end fw-semibold">
            {targetChainPaymentAddress}
          </Col>
        </Row>
        <Row gutter={16} className="mt-2">
          <Col span={12}>Payment reference</Col>
          <Col span={12} className="d-flex justify-content-end fw-semibold ">
            <Tooltip title={paymentReference}>
              <span className="text-truncate">{paymentReference}</span>
            </Tooltip>
          </Col>
        </Row>
      </div>
      <Button
        type="primary"
        className="bg-black fw-semibold mt-4"
        style={{ width: "100%" }}
        onClick={onPayment}
        loading={isLoading}
      >
        Pay
      </Button>
      <VerifyPayment smartContract={smartContract} ref={refVerifyPayment} />
    </Modal>
  );
}

export default forwardRef(PaymentMethodPopup);

import React from "react";
import { Switch } from "antd";
import { DEFAULT_BRAND_NAME } from "../../constants";

const Header = ({ mintPage, isDarkMode, onSwitchChange }) => {
  return (
    <header className="header-two">
      <nav className="navbar navbar-expand-lg pe-0">
        <div className="container px-0">
          <a className="navbar-brand">
            <p className="brand-stabillo">
              {mintPage.brandName || DEFAULT_BRAND_NAME}
            </p>
          </a>

          <ul className="navbar-nav flex-row ms-auto align-items-center">
            <li className="nav-item pe-0">
              <Switch
                checked={isDarkMode}
                onChange={onSwitchChange}
                checkedChildren={<img src="/template/light.svg" alt="light" />}
                unCheckedChildren={
                  <img src="/template/home-two/dark.svg" alt="dark" />
                }
              />
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;

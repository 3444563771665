import { Card, Col, Row } from "antd";
import React from "react";
import { DEFAULT_TEXT_DESCRIPTION, MINT_PAGE_COMPONENT } from "../../constants";

const JoinNft = ({ mintPage = {} }) => {
  const descriptionComponent = mintPage.components.find(
    (component) =>
      component.componentType.name.toLowerCase() ===
      MINT_PAGE_COMPONENT.DESCRIPTION.toLowerCase()
  );

  const text = descriptionComponent.text || DEFAULT_TEXT_DESCRIPTION;
  return (
    <>
      <section className="join-section">
        <div className="container">
          {text && (
            <Row>
              <Col xs={24}>
                <Card>
                  <div className="description">
                    <h2 className="main-heading">{text}</h2>
                  </div>
                  <img src="/template/join-bg.png" alt="" />
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </section>
    </>
  );
};

export default JoinNft;

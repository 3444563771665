import Provider from ".";
import {
  CrossmarkAdaptor,
  WalletConnectAdaptor,
  XummAdaptor,
} from "../connect/adaptors";
import { WALLET_CONNECT_PROJECT_ID } from "../constants";

// export const metadata: Metadata = {
//   title: 'Create Next App',
//   description: 'Generated by create next app',
// }

const xumm = new XummAdaptor({
  apiKey: "7fcb00b9-b846-4ddf-ae02-2a94f18c0b2f",
});
const crossmark = new CrossmarkAdaptor();
const walletconnect = new WalletConnectAdaptor({
  projectId: WALLET_CONNECT_PROJECT_ID,
  networks: ["testnet"],
});

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Provider adaptors={[xumm, crossmark, walletconnect]}>{children}</Provider>
  );
}

import { Col, Row } from "antd";
import React from "react";
import { DEFAULT_TITLE_HERO, MINT_PAGE_COMPONENT } from "../../constants";

const HeroComponent = ({ mintPage = {} }) => {
  const heroComponent = mintPage.components.find(
    (component) =>
      component.componentType.name.toLowerCase() ===
      MINT_PAGE_COMPONENT.HERO.toLowerCase()
  );

  const componentImages =
    heroComponent.componentImageUpdated || heroComponent.componentImages || [];
  const text = heroComponent.text || DEFAULT_TITLE_HERO;

  const generateTitle = () => {
    return (
      <div className="hero-content">
        <h1 className="main-heading mt-md-5 pt-md-4">{text}</h1>
      </div>
    );
  };

  const generateImages = () => {
    const isHaveImage = componentImages && componentImages.length > 0;
    const artImg1 = isHaveImage
      ? componentImages[0]?.url
      : "/template/art/1.png";
    const artImg2 = isHaveImage
      ? componentImages[1]?.url
      : "/template/art/2.png";
    const artImg3 = isHaveImage
      ? componentImages[2]?.url
      : "/template/art/3.png";

    return (
      <Row>
        <Col xs={7} md={8}>
          <img className="img-fluid hero-left-img" src={artImg1} alt="" />
        </Col>
        <Col xs={10} md={8}>
          <img className="img-fluid hero-center-img" src={artImg2} alt="" />
        </Col>
        <Col xs={7} md={8}>
          <img className="img-fluid hero-right-img" src={artImg3} alt="" />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <section className="hero-section">
        <div className="container">
          <Row>
            <Col xs={24} className="text-center">
              {generateImages()}
              {generateTitle()}
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default HeroComponent;

import { Col, Row } from "antd";
import React from "react";
import { DEFAULT_TITLE_HERO, MINT_PAGE_COMPONENT } from "../../constants";
import ConnectButton from "../ConnectButton";
// import { ConnectButton } from "@rainbow-me/rainbowkit";

const HeroComponent = ({ mintPage = {} }) => {
  const heroComponent = mintPage.components.find(
    (component) =>
      component.componentType.name.toLowerCase() ===
      MINT_PAGE_COMPONENT.HERO.toLowerCase()
  );
  const componentImages =
    heroComponent.componentImageUpdated || heroComponent.componentImages || [];
  const text = heroComponent.text || DEFAULT_TITLE_HERO;

  const generateTitle = () => {
    return <h1 className="main-heading pb-5">{text}</h1>;
  };

  const generateImages = () => {
    const isHaveImage = componentImages && componentImages.length > 0;
    const heroImg = isHaveImage
      ? componentImages[0].url
      : "/template/home-two/hero.png";

    return (
      <div className="container-hero-stabillo">
        <img src={heroImg} alt="" />
      </div>
    );
  };

  return (
    <>
      <section className="hero-section hero-two-section pt-3 pb-0">
        <div className="container">
          <Row>
            <Col xs={24} lg={12}>
              {generateTitle()}
            </Col>
            <Col xs={24} lg={12} className="text-center mb-5">
              <div className="connect-wallet-stabillo">
                <ConnectButton />
              </div>
            </Col>
          </Row>
        </div>
        {generateImages()}
      </section>
    </>
  );
};

export default HeroComponent;

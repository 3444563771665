import React from "react";
import { Switch } from "antd";
import { DEFAULT_BRAND_NAME } from "../../constants";
import ConnectButton from '../ConnectButton';

const Header = ({ mintPage, isDarkMode, onSwitchChange }) => {
  return (
    <header className="header-three">
      <nav className="navbar navbar-expand-lg pe-0">
        <div className="container px-0">
          <a className="navbar-brand">
            {isDarkMode ? (
              <p className="brand-calm-light">
                {mintPage.brandName || DEFAULT_BRAND_NAME}
              </p>
            ) : (
              <p className="brand-calm-dark">
                {mintPage.brandName || DEFAULT_BRAND_NAME}
              </p>
            )}
          </a>

          <ul className="navbar-nav flex-row ms-auto align-items-center">
            <li className="nav-item pe-2">
              <Switch
                checked={isDarkMode}
                onChange={onSwitchChange}
                checkedChildren={
                  <img src="/template/home-three/light.svg" alt="light" />
                }
                unCheckedChildren={
                  <img src="/template/home-three/dark.svg" alt="dark" />
                }
              />
            </li>

            <li className="nav-item">
              <div
                className={`${
                  isDarkMode
                    ? "connect-wallet-calm-light"
                    : "connect-wallet-calm-dark"
                }`}
              >
                <ConnectButton />
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;

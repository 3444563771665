import { Button, Col, Flex, message, Row, Select, Slider } from "antd";
import BigNumber from "bignumber.js";
import {
  BrowserProvider,
  ContractFactory,
  ethers,
  JsonRpcSigner,
} from "ethers";
import React, { useEffect, useRef, useState } from "react";
import { useWalletClient } from "wagmi";
import ConnectButton from "../../../components/ConnectButton";
import {
  DEFAULT_BRAND_NAME,
  DEFAULT_TEXT_DESCRIPTION,
  MINT_PAGE_COMPONENT,
} from "../../../constants";
import { contractMultichain } from "../../../contract/contractByteMultichainAbi";
import { isEmpty } from "../../../utils/object";
import ConnectWalletPopup from "./ConnectWalletPopup";
import ContractDetail from "./ContractDetail";
import CreatorInfo from "./CreatorInfo";

const DEFAULT_STEP = 1;
const DEFAULT_AMOUNT = 1;

function RightContent({
  mintPage = {},
  onMint,
  mintingData = {
    nextToken: null,
    isReady: false,
    isMintSubmitting: false,
  },
  amount,
  onUpdateAmount,
  contractData,
  smartContract,
  paymentCurrency,
  onChangePaymentCurrency,
}) {
  const refConnectWalletPop = useRef(null);
  const [reservation, setReservation] = useState({});
  const [isReservation, setIsReservation] = useState(false);

  const { isReady } = mintingData;

  const MAX_AMOUNT =
    Number(contractData?.size) - Number(contractData.totalMinted);

  const onChange = (newValue) => {
    onUpdateAmount(newValue);
  };

  const handleIncrement = () => {
    onUpdateAmount(amount + 1);
  };

  const handleDecrement = () => {
    if (amount > 0) {
      onUpdateAmount(amount - 1);
    }
  };

  const descriptionComponent = mintPage.components.find(
    (component) =>
      component.componentType.name.toLowerCase() ===
      MINT_PAGE_COMPONENT.DESCRIPTION.toLowerCase()
  );

  const description = descriptionComponent.text || DEFAULT_TEXT_DESCRIPTION;

  useEffect(() => {
    if (!isEmpty(reservation)) {
      refConnectWalletPop.current?.onShow({
        payableAmount: reservation?.payableAmount,
        paymentReference: reservation?.paymentReference,
        targetChainPaymentAddress: reservation?.targetChainPaymentAddress,
      });
    }
  }, [reservation]);

  const onMintToken = () => {
    const totalAmount = Number(contractData?.totalMinted) + Number(amount);
    const isExceeded = totalAmount >= Number(contractData?.maxTokensPerAddress);
    const nftRemaining =
      Number(contractData?.maxTokensPerAddress) -
      Number(contractData?.totalMinted);

    if (isExceeded) {
      message.error(`You can only mint ${nftRemaining} more NFTs`);
      return;
    }
    onMint();
  };

  const onPaymentCurrency = () => {
    onReserve();
  };

  const handleMint = () => {
    if (paymentCurrency === "xrp") {
      onPaymentCurrency();
    } else {
      onMintToken();
    }
  };

  const { data: walletClient, isLoading } = useWalletClient();

  function walletClientToSigner(walletClient) {
    const { account, chain, transport } = walletClient;
    const network = {
      chainId: chain.id,
      name: chain.name,
      ensAddress: chain.contracts?.ensRegistry?.address,
    };
    const provider = new BrowserProvider(transport, network);
    const signer = new JsonRpcSigner(provider, account.address);
    return signer;
  }

  const onReserve = async () => {
    const atrivAddress = smartContract?.contractAddress; // Replace with your contract address
    const signer = walletClientToSigner(walletClient);
    const contractDeploy = new ContractFactory(
      contractMultichain.abi,
      contractMultichain.bytecode,
      signer
    );
    setIsReservation(true);
    const atriv = await contractDeploy.attach(atrivAddress);

    // Interact with the getPriceInTargetCurrency function
    const symbol = "XRP"; // or any other symbol you want
    const price = await atriv.getPriceInTargetCurrency(symbol);
    console.log(
      `Price in target currency for ${symbol}:`,
      ethers.formatUnits(price, 6)
    );

    // Interact with the reserve function
    const chainSymbol = "XRP"; // or any other chain symbol you want

    // Send a transaction to call the reserve function
    const tx = await atriv.reserve(chainSymbol);
    console.log("Transaction hash:", tx.hash);

    // Wait for the transaction to be mined
    const receipt = await tx.wait();
    console.log("Transaction was mined in block", receipt.blockNumber);

    // Extract and log the reservation info from the return value
    console.log("receipt", receipt);
    const event = receipt.events?.find(
      (event) => event.event === "ReservationCreated"
    );
    setIsReservation(false);
    if (event) {
      console.log("Event args:", event.args);
      const account = event.args[0];
      const tokenId = event.args[1];
      const chain = event.args[2];
      const paymentReference = event.args[3];
      const targetAddress = event.args[4];
      const payableAmount = event.args[5];
      const reservationTime = event.args[6];

      // Create the Reservation object as it is stored in the contract
      const reservation = {
        chain: chain,
        tokenId: tokenId,
        payableAmount: payableAmount,
        reservationTime: reservationTime,
        paymentReference: paymentReference,
        targetChainPaymentAddress: targetAddress,
      };
      console.log("Reservation......,", reservation);
      // Log the Reservation object
      console.log("Reservation Info:");
      console.log(`  Chain: ${reservation.chain}`);
      console.log(`  Token ID: ${reservation.tokenId.toString()}`);
      console.log(
        `  Payable Amount: ${ethers.formatUnits(reservation.payableAmount, 6)}`
      );
      console.log(`  Payment Reference: ${reservation.paymentReference}`);
      console.log(
        `  Target Chain Payment Address: ${reservation.targetChainPaymentAddress}`
      );
      setReservation({
        chain: reservation.chain,
        tokenId: reservation.tokenId.toString(),
        payableAmount: ethers.formatUnits(reservation.payableAmount, 6),
        paymentReference: reservation.paymentReference,
        targetChainPaymentAddress: reservation.targetChainPaymentAddress,
      });
    } else {
      const iface = new ethers.Interface(contractMultichain.abi);

      console.log("receipt.logs", receipt.logs);
      setIsReservation(false);
      receipt.logs.forEach((log) => {
        try {
          const parsedLog = iface.parseLog(log);
          if (parsedLog.name === "ReservationCreated") {
            console.log("Event args:", parsedLog.args);
            const account = parsedLog.args[0];
            const tokenId = parsedLog.args[1];
            const chain = parsedLog.args[2];
            const paymentReference = parsedLog.args[3];
            const targetAddress = parsedLog.args[4];
            const payableAmount = parsedLog.args[5];
            const reservationTime = parsedLog.args[6];

            // Create the Reservation object as it is stored in the contract
            const reservation = {
              chain: chain,
              tokenId: tokenId,
              payableAmount: payableAmount,
              reservationTime: reservationTime,
              paymentReference: paymentReference,
              targetChainPaymentAddress: targetAddress,
            };
            console.log("Reservation......,", reservation);
            // Log the Reservation object
            console.log("Reservation Info:");
            console.log(`  Chain: ${reservation.chain}`);
            console.log(`  Token ID: ${reservation.tokenId.toString()}`);
            console.log(
              `  Payable Amount: ${ethers.formatUnits(
                reservation.payableAmount,
                6
              )}`
            );
            // console.log(
            //   `  Reservation Time: ${new Date(
            //     reservation.reservationTime * 1000
            //   ).toISOString()}`
            // );
            console.log(`  Payment Reference: ${reservation.paymentReference}`);
            console.log(
              `  Target Chain Payment Address: ${reservation.targetChainPaymentAddress}`
            );
            setReservation({
              chain: reservation.chain,
              tokenId: reservation.tokenId.toString(),
              payableAmount: ethers.formatUnits(reservation.payableAmount, 6),
              // reservationTime: new Date(
              //   reservation.reservationTime * 1000
              // ).toISOString(),
              paymentReference: reservation.paymentReference,
              targetChainPaymentAddress: reservation.targetChainPaymentAddress,
            });
          }
        } catch (e) {
          setIsReservation(false);
          console.log(
            "ReservationCreated event not found in the transaction receipt.",
            e
          );
        }
      });
    }
  };

  return (
    <div
      style={{ flex: 1, backgroundColor: "#151517", color: "white" }}
      className="p-5 d-flex main-right"
    >
      <div className="d-flex flex-column" style={{ flex: 1 }}>
        <div className="d-flex justify-content-end">
          <ConnectButton />
        </div>
        <h2 className="main-right-title mt-5">
          {mintPage.brandName || DEFAULT_BRAND_NAME}
        </h2>
        <span className="main-right-des">{description}</span>
        <CreatorInfo smartContract={smartContract} mintPage={mintPage} />
        <hr />
        <div>
          <Row className="mb-3">
            <Col span={8}>
              <div className="d-flex flex-column">
                <span className="main-right-info-title">Mint Price</span>
                <span className="fs-3 fw-bold">
                  {contractData?.mintPrice} {smartContract.network}
                </span>
              </div>
            </Col>
            <Col span={8}>
              <div className="d-flex flex-column text-center">
                <span className="main-right-info-title">Total Minted</span>
                <span className="fs-3 fw-bold">
                  {contractData.totalMinted}/{smartContract?.size}
                </span>
              </div>
            </Col>
            <Col span={8}>
              <div className="d-flex flex-column">
                <span className="main-right-info-title">Type</span>
                <span className="fs-3 fw-bold">Limited</span>
              </div>
            </Col>
          </Row>

          <ContractDetail mintPage={mintPage} />

          {paymentCurrency !== "xrp" && (
            <div className="border rounded-2 p-4">
              <span>{contractData.network}</span>
              <div className="d-flex align-items-center justify-content-between">
                <button
                  disabled={
                    Number(contractData.totalMinted) ===
                    Number(smartContract?.size)
                  }
                  className="bg-transparent border-0"
                  onClick={handleDecrement}
                >
                  <img src={"/button/minus.png"} alt="" />
                </button>
                <span className="fs-1 fw-bold">{amount}</span>
                <button
                  disabled={
                    Number(contractData.totalMinted) ===
                    Number(smartContract?.size)
                  }
                  className="bg-transparent border-0"
                  onClick={handleIncrement}
                >
                  <img src={"/button/plus.png"} alt="" />
                </button>
              </div>
              <Slider
                min={DEFAULT_AMOUNT}
                max={MAX_AMOUNT}
                step={DEFAULT_STEP}
                onChange={onChange}
                value={amount}
              />
              <Flex align="center" justify="space-between" style={{ flex: 1 }}>
                <Flex align="center">
                  <span>Total: </span>
                  <Flex gap={"0.3em"} align="center" className="mx-2">
                    <span>
                      {new BigNumber(contractData.mintPrice)
                        .multipliedBy(new BigNumber(amount))
                        .toString()}
                    </span>
                    <img
                      src={smartContract.image}
                      alt={smartContract.network}
                      style={{ width: 17, height: 17 }}
                    />
                  </Flex>
                </Flex>
                <p className="text-end mt-4">
                  Max Mint Amount: {contractData.maxTokensPerAddress}
                </p>
              </Flex>
            </div>
          )}
        </div>

        <div className="mt-3">
          <span>Payment Currency</span>
          <Select
            defaultValue="xrp"
            onChange={onChangePaymentCurrency}
            style={{ width: "100%" }}
            className="my-3"
            options={[
              { value: "xrp", label: "XRP" },
              { value: "coston", label: "Coston" },
              // { value: "coston2", label: "Coston2" },
              // { value: "flare", label: "Flare" },
              // { value: "songbird", label: "Songbird" },
            ]}
          />
        </div>

        <div
          className="d-flex justify-content-end align-items-end"
          style={{ flex: 1 }}
        >
          <Button
            style={{ width: "100%" }}
            onClick={handleMint}
            loading={isReservation || mintingData.isMintSubmitting}
            disabled={
              !isReady ||
              Number(amount) > Number(contractData.maxTokensPerAddress) ||
              Number(contractData.totalMinted) === Number(smartContract?.size)
            }
          >
            <span className="fs-6 fw-semibold">
              {paymentCurrency === "xrp" ? "Reserve" : "Mint"}
            </span>
          </Button>
        </div>
      </div>
      {/* <PaymentMethodPopup
        smartContract={smartContract}
        ref={refPaymentMethodPopup}
      /> */}
      <ConnectWalletPopup
        smartContract={smartContract}
        ref={refConnectWalletPop}
      />
    </div>
  );
}

export default RightContent;

import { Col, Row } from "antd";
import React from "react";

const Rating = ({ contractData }) => {
  const ratingData = [
    {
      id: 1,
      title: "922K+",
      content: "Total Supply",
      value: contractData.totalSupply,
    },
    {
      id: 2,
      title: "29K+",
      content: "Owners",
      value: contractData.owners,
    },
    {
      id: 3,
      title: "723K+",
      content: "Minted",
      value: contractData.totalMinted,
    },
  ];

  return (
    <>
      <section className="rating-section rating-two-section rating-three-section">
        <div className="container">
          <Row>
            <Col xs={24}>
              <ul>
                {ratingData.map((item) => (
                  <li key={item.id}>
                    <h2>{item.value}</h2>
                    <p>{item.content}</p>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Rating;

import React from "react";
import { Switch } from "antd";
import { DEFAULT_BRAND_NAME } from "../../constants";
import ConnectButton from "../ConnectButton";
// import { ConnectButton } from "@rainbow-me/rainbowkit";

const HeaderComponent = ({ mintPage, isDarkMode, onSwitchChange }) => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg pe-0">
        <div className="container px-0">
          <a
            href="true"
            className="navbar-brand"
            onClick={(e) => e.preventDefault()}
          >
            <p className="brand-purp">
              {mintPage.brandName || DEFAULT_BRAND_NAME}
            </p>
          </a>

          <ul className="navbar-nav flex-row ms-auto align-items-center">
            <li className="nav-item pe-2 pe-md-4">
              <Switch
                checked={isDarkMode}
                onChange={onSwitchChange}
                checkedChildren={<img src="/template/light.svg" alt="light" />}
                unCheckedChildren={<img src="/template/dark.svg" alt="dark" />}
              />
            </li>

            <li className="nav-item">
              {/* <div className="connect-wallet-purp"> */}
                <ConnectButton />
              {/* </div> */}
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default HeaderComponent;

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createWeb3Modal } from "@web3modal/wagmi/react";

import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { ConfigProvider, Empty } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";
import { Route, Routes } from "react-router-dom";
import { WagmiProvider, http } from "wagmi";
import Adaptor from "./providers/Adaptor";

import {
  arbitrum,
  arbitrumSepolia,
  avalanche,
  base,
  baseSepolia,
  bsc,
  bscTestnet,
  chiliz,
  cronos,
  etherlink,
  etherlinkTestnet,
  fantom,
  fantomTestnet,
  flare,
  flareTestnet,
  gnosis,
  gnosisChiado,
  holesky,
  linea,
  lineaTestnet,
  mainnet,
  moonbaseAlpha,
  moonbeam,
  moonriver,
  optimism,
  optimismSepolia,
  palm,
  polygon,
  polygonAmoy,
  pulsechain,
  sepolia,
  songbird,
  songbirdTestnet,
} from "wagmi/chains";
import "./App.scss";
import Home from "./pages/home";

const projectId = "1444614941892d539b7c5046af445f43";
const chains = [
  flare,
  flareTestnet,
  songbird,
  songbirdTestnet,
  polygon,
  polygonAmoy,
  sepolia,
  holesky,
  bsc,
  bscTestnet,
  arbitrum,
  arbitrumSepolia,
  base,
  baseSepolia,
  optimism,
  optimismSepolia,
  linea,
  lineaTestnet,
  avalanche,
  fantom,
  fantomTestnet,
  cronos,
  palm,
  gnosis,
  gnosisChiado,
  chiliz,
  // chilizTestnet,
  pulsechain,
  moonbeam,
  moonriver,
  moonbaseAlpha,
  etherlink,
  etherlinkTestnet,
];

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};
function App() {
  const queryClient = new QueryClient();

  const wagmiConfig = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
    transports: {
      [mainnet.id]: http(),
      [flare.id]: http(),
      [flareTestnet.id]: http(),
      [songbird.id]: http(),
      [songbirdTestnet.id]: http(),
      [polygon.id]: http(),
      [polygonAmoy.id]: http(),
      [sepolia.id]: http(),
      [holesky.id]: http(),
      [bsc.id]: http(),
      [bscTestnet.id]: http(),
      [arbitrum.id]: http(),
      [arbitrumSepolia.id]: http(),
      [base.id]: http(),
      [baseSepolia.id]: http(),
      [optimism.id]: http(),
      [optimismSepolia.id]: http(),
      [linea.id]: http(),
      [lineaTestnet.id]: http(),
      [avalanche.id]: http(),
      [fantom.id]: http(),
      [fantomTestnet.id]: http(),
      [cronos.id]: http(),
      [palm.id]: http(),
      [gnosis.id]: http(),
      [gnosisChiado.id]: http(),
      [chiliz.id]: http(),
      [pulsechain.id]: http(),
      [moonbeam.id]: http(),
      [moonriver.id]: http(),
      [moonbaseAlpha.id]: http(),
      [etherlink.id]: http(),
      [etherlinkTestnet.id]: http(),
    },
  });

  createWeb3Modal({
    wagmiConfig,
    projectId,
    chains,
    themeMode: "light",
    themeVariables: {
      "--w3m-font-family": "Raleway",
      "--w3m-accent": "black",
    },
  });

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#302e30",
        },
        components: {
          Slider: {
            railSize: 12,
            handleSize: 20,
            borderRadiusLG: 10,
            borderRadius: 10,
            borderRadiusOuter: 10,
            borderRadiusSM: 10,
            borderRadiusXS: 10,
          },
        },
      }}
    >
      <WagmiProvider config={wagmiConfig}>
        <Adaptor>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route path="/" element={<Empty />} />
              <Route path="/:id" element={<Home />} />
            </Routes>
          </QueryClientProvider>
        </Adaptor>
      </WagmiProvider>
    </ConfigProvider>
  );
}

export default App;

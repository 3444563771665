import React, { useState } from "react";
import Footer from "../components/homeTwo/footer";
import Rating from "../components/homeTwo/rating";
import Hero from "../components/homeTwo/hero";
import JoinNft from "../components/homeTwo/JoinNft";
import Collection from "../components/homeTwo/collection";
import FreeMint from "../components/homeTwo/FreeMint";
import Header from "../components/homeTwo/header";

const TemplateStabillo = ({
  mintPage,
  onMint,
  mintingData,
  amount,
  onUpdateAmount,
  contractData,
  smartContract,
}) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleSwitchChange = () => {
    setIsDarkMode(!isDarkMode);
  };

  const containerStyles = {
    backgroundImage: isDarkMode
      ? 'url("/home-two/dark-bg.png")'
      : 'url("/home-two/light-bg.png")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <>
      <div
        style={containerStyles}
        className={`theme-switcher ${
          isDarkMode ? "dark-mode-two" : "light-mode-two"
        }`}
      >
        <Header
          mintPage={mintPage}
          isDarkMode={isDarkMode}
          onSwitchChange={handleSwitchChange}
        />

        <Hero mintPage={mintPage} />
        <Rating mintPage={mintPage} contractData={contractData} />
        <FreeMint
          onMint={onMint}
          mintingData={mintingData}
          amount={amount}
          contractData={contractData}
          onUpdateAmount={onUpdateAmount}
          mintPage={mintPage}
          smartContract={smartContract}
        />
        <Collection mintPage={mintPage} />
        <JoinNft mintPage={mintPage} />
        <Footer mintPage={mintPage} />
      </div>
    </>
  );
};

export default TemplateStabillo;

import { Col, Row } from "antd";
import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import { smartContractService } from "../../services";
import { useState } from "react";
import * as Lodash from "lodash";
const Collection = ({ mintPage }) => {
  const [tokens, setTokens] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [collectionData2, setCollectionData2] = useState([]);

  // const collectionData = [
  //   { id: 1, img: "/template/collection/1.png" },
  //   { id: 2, img: "/template/collection/2.png" },
  //   { id: 3, img: "/template/collection/3.png" },
  //   { id: 4, img: "/template/collection/4.png" },
  //   { id: 5, img: "/template/collection/5.png" },
  //   { id: 6, img: "/template/collection/6.png" },
  //   { id: 7, img: "/template/collection/7.png" },
  //   { id: 8, img: "/template/collection/8.png" },
  // ];

  // const collectionData2 = [
  //   { id: 1, img: "/template/collection/5.png" },
  //   { id: 2, img: "/template/collection/6.png" },
  //   { id: 3, img: "/template/collection/7.png" },
  //   { id: 4, img: "/template/collection/8.png" },
  //   { id: 5, img: "/template/collection/9.png" },
  //   { id: 6, img: "/template/collection/1.png" },
  //   { id: 7, img: "/template/collection/2.png" },
  // ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5.5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4.5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const responsive2 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const getTokens = async () => {
    const tokens = await smartContractService.getTokensBySmartContract(
      mintPage.smartContractId
    );
    if (!tokens || Lodash.isEmpty(tokens)) return null;
    const { tokens: tokenList = [] } = tokens;
    setTokens(tokenList);

    if (tokenList.length >= 10) {
      const collectionData = tokenList.slice(0, tokenList.length / 2);
      setCollectionData(collectionData);

      const collectionData2 = tokenList.slice(tokenList.length / 2);
      setCollectionData2(collectionData2);
    } else {
      setCollectionData(tokenList);
    }
  };

  useEffect(() => {
    getTokens();
  }, []);

  return (
    <>
      <section className="collection-section">
        <div className="container-xl">
          {tokens && tokens.length > 0 && (
            <Row align="middle">
              <Col xs={24}>
                <h2 className="main-heading text-center mb-5 pb-3">
                  Collections
                </h2>

                <Carousel
                  responsive={responsive}
                  arrows={false}
                  autoPlay={true}
                  autoPlaySpeed={1500}
                >
                  {collectionData.map((item) => (
                    <div key={item.id}>
                      <img className="img-fluid" src={item.image} alt="1" />
                    </div>
                  ))}
                </Carousel>

                <Carousel
                  className="mt-3"
                  responsive={responsive2}
                  arrows={false}
                  autoPlay={true}
                  autoPlaySpeed={2000}
                >
                  {collectionData2.map((item) => (
                    <div key={item.id}>
                      <img className="img-fluid" src={item.image} alt="1" />
                    </div>
                  ))}
                </Carousel>
              </Col>
            </Row>
          )}
        </div>
      </section>
    </>
  );
};

export default Collection;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mintPageService, smartContractService } from "../services";
import {
  flare,
  flareTestnet,
  polygon,
  polygonMumbai,
  songbird,
  songbirdTestnet,
} from "wagmi/chains";
import MintPage from "./mint-page";
import { useAccount } from "wagmi";

const NETWORK_CHAIN_MAP = {
  "flare-mainnet": flare,
  coston2: flareTestnet,
  "songbird-mainnet": songbird,
  coston: songbirdTestnet,
  matic: polygon,
  maticmum: polygonMumbai,
};

const ALL_CHAINS = [
  flare,
  flareTestnet,
  songbird,
  songbirdTestnet,
  polygon,
  polygonMumbai,
];
const Home = () => {
  const { id } = useParams();
  const [mintPage, setMintPage] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [smartContract, setSmartContract] = useState({});
  const [mintingData, setMintingData] = useState({
    nextToken: null,
    isReady: false,
    isMintSubmitting: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const getContract = async (smartContractId) => {
    if (!smartContractId) return null;
    const contract = await smartContractService.getSmartContract(
      smartContractId
    );
    contract.symbol =
      NETWORK_CHAIN_MAP[contract.network]?.nativeCurrency?.symbol;
    return contract;
  };

  const getTemplates = async () => {
    const template = await mintPageService.getTemplateTypes();
    return template;
  };

  const getMintPage = async (id) => {
    const mintPage = await mintPageService.getMintPage(id);
    return mintPage;
  };

  const getNextToken = async (smartContract) => {
    if (!smartContract) return null;
    const nextToken = await mintPageService.getNextToken(
      smartContract.contractAddress,
      smartContract.network
    );
    return nextToken;
  };

  const initData = async () => {
    setIsLoading(true);
    const [templates, mintPage] = await Promise.all([
      getTemplates(),
      getMintPage(id),
    ]);
    if (!templates) return;
    if (!mintPage) return;
    setTemplates(templates);
    setMintPage(mintPage);

    const contract = await getContract(mintPage.smartContractId);
    if (!contract) return;
    setSmartContract(contract);

    const nextToken = await getNextToken(contract);
    if (!nextToken) return;

    setMintingData({ ...mintingData, nextToken, isReady: true });
    setIsLoading(false);
  };

  const onMintSuccess = async () => {
    const nextToken = await getNextToken(smartContract);
    if (!nextToken) return;
    setMintingData({ ...mintingData, nextToken, isMintSubmitting: false });
  };

  const onMinting = (isMintSubmitting) => {
    setMintingData({ ...mintingData, isMintSubmitting });
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <>
      <MintPage
        mintPage={mintPage}
        smartContract={smartContract}
        templates={templates}
        mintingData={mintingData}
        onMintSuccess={onMintSuccess}
        onMinting={onMinting}
      />
    </>
  );
};

export default Home;

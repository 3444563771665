import { useEffect, useState } from "react";
import { useAccount } from "wagmi";

const BACKGROUND_COLOR = "#0C0F19";
const TEXT_COLOR = "white";
const BORDER_RADIUS = "8px";
const HEIGHT = "38px";
const WIDTH = "100%";

const ConnectButton = () => {
  const { isDisconnected } = useAccount();
  const w3mBtn = document.querySelector("w3m-button")?.shadowRoot;
  const w3mConnectBtn = w3mBtn?.querySelector("w3m-connect-button");
  const w3mAccountBtn = w3mBtn?.querySelector("w3m-account-button");
  const [isShowW3mBtn, setIsShowW3mBtn] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  useEffect(() => {
    if (isDisconnected && !w3mConnectBtn && w3mAccountBtn) {
      setIsShowW3mBtn(false);
      return;
    }

    if (w3mConnectBtn) {
      const button = w3mConnectBtn?.shadowRoot
        ?.querySelector("wui-connect-button")
        ?.shadowRoot?.querySelector("button");

      const spinner = button
        ?.querySelector("wui-loading-spinner")
        ?.shadowRoot?.querySelector("svg")
        ?.querySelector("circle");
      if (spinner) {
        spinner.style.stroke = TEXT_COLOR;
      }

      const textConnecting = button
        ?.querySelector("wui-text")
        ?.shadowRoot?.querySelector("slot");
      if (textConnecting) {
        textConnecting.style.color = TEXT_COLOR;
      }

      button.addEventListener("click", (e) => {
        setIsConnecting(!isConnecting);
      });
      if (button) {
        setIsShowW3mBtn(true);
      } else {
        setIsShowW3mBtn(false);
      }

      button.style.height = HEIGHT;
      button.style.width = WIDTH;
      button.style.backgroundColor = BACKGROUND_COLOR;
      button.style.borderRadius = BORDER_RADIUS;
    }

    if (w3mAccountBtn) {
      const wuiAccountBtn =
        w3mAccountBtn.shadowRoot.querySelector("wui-account-button").shadowRoot;
      const button = wuiAccountBtn.querySelector("button");
      const wuiFlex = wuiAccountBtn.querySelector("wui-flex");
      const wuiBalance = wuiAccountBtn.querySelector("wui-text");
      const wuiAddress = wuiFlex.querySelector("wui-text");

      if (button) {
        setIsShowW3mBtn(true);
      } else {
        setIsShowW3mBtn(false);
      }

      button.style.height = HEIGHT;
      button.style.borderRadius = BORDER_RADIUS;
      button.style.backgroundColor = BACKGROUND_COLOR;

      wuiFlex.style.color = TEXT_COLOR;
      wuiBalance.style.color = TEXT_COLOR;
      wuiAddress.style.color = TEXT_COLOR;
    }
  }, [w3mConnectBtn, w3mAccountBtn, isDisconnected, w3mBtn, isConnecting]);
  return (
    <div className={isShowW3mBtn ? "" : "hide"}>
      <w3m-button />
    </div>
  );
};

export default ConnectButton;

import { CHAINS } from "../constants";
import { BaseService } from "./base.service";

class SmartContractService extends BaseService {
  formatToken(token) {
    return {
      id: token.tokenId,
      key: token.tokenId,
      tokenUrl: token.tokenUrl,
      name: token?.tokenData?.name,
      description: token?.tokenData?.description,
      image: token?.tokenData?.image,
    };
  }
  formatSmartContract(smartContract) {
    const contract = {
      id: smartContract.id,
      name: smartContract.name,
      contractAddress:
        smartContract.contractAddress || smartContract.contract_address,
      size: smartContract.size,
      network: smartContract.network,
      creator: smartContract.creator,
      type: smartContract.type,
      useWalletI: smartContract.userWalletId,
      projectId: smartContract.projectId,
      collectionId: smartContract.collectionId,
      blockchain: this.getBlockchainName(smartContract.network),
      image: this.getIconContract(smartContract.network),
      contractAddressLink: this.getAddressLinkBlockExplore(
        smartContract.network,
        smartContract.contractAddress || smartContract.contract_address
      ),
      creatorAddressLink: this.getAddressLinkBlockExplore(
        smartContract.network,
        smartContract.creator
      ),
      nameCreator: smartContract.nameCreator,
    };
    return contract;
  }

  formatTransactionHistory(network, transaction) {
    return {
      id: transaction.id,
      key: transaction.id,
      from: transaction.from,
      to: transaction.to,
      price: transaction.price,
      fromAddressLink: this.getAddressLinkBlockExplore(
        network,
        transaction.from
      ),
      toAddressLink: this.getAddressLinkBlockExplore(network, transaction.to),
    };
  }

  getAddressLinkBlockExplore(network, address) {
    if (!address) return "";
    if (!network) return "";
    return `${CHAINS[network]?.blockExplore}/address/${address}`;
  }

  getIconContract(network) {
    return CHAINS[network]?.icon;
  }

  getBlockchainName(network) {
    return CHAINS[network]?.name;
  }

  async createSmartContract(data) {
    return this.post("/smartContracts", data);
  }

  async getSmartContractsByProject(projectId) {
    const resp = await this.get(`/projects/${projectId}/smartContracts`);
    if (!resp) return [];
    return resp.map((smartContract) => this.formatSmartContract(smartContract));
  }

  async getTokensBySmartContract(smartContractId) {
    const resp = await this.get(`/smartContracts/${smartContractId}/tokens`);
    if (!resp) return {};
    return {
      contractAddress: resp.contractAddress,
      network: resp.network,
      collectionBannerUrl: resp.collectionBannerUrl,
      tokens:
        resp.tokens && resp.tokens.length
          ? resp.tokens.map(this.formatToken)
          : [],
    };
  }

  async getTokenInfo(network, contractAddress, tokenId) {
    const resp = await this.get(
      `/smartContracts/getTokenInfo/${network}/${contractAddress}/${tokenId}`
    );
    if (!resp) return {};
    if (resp.owner) {
      resp.ownerAddressLink = this.getAddressLinkBlockExplore(
        network,
        resp.owner
      );
    }
    if (resp.transactions && resp.transactions.length > 0) {
      resp.transactions = resp.transactions.map((txn) =>
        this.formatTransactionHistory(network, txn)
      );
    }

    return resp;
  }

  async getSmartContract(smartContractId) {
    const resp = await this.get(`/smartContracts/${smartContractId}`);
    if (!resp) return {};
    return this.formatSmartContract(resp);
  }
}

export const smartContractService = new SmartContractService();

import { BaseService } from "./base.service";
import { MINT_PAGE_COMPONENT, ELEMENT_UPDATE } from "../constants";

class MintPageService extends BaseService {
  formatMintPage(mintPage) {
    const format = {
      ...mintPage,
      templateMode: mintPage.template.backgroundColor,
      templateTypeId: mintPage.template.templateTypeId,
      templateId: mintPage.template.id,
      components: mintPage.template.components,
    };

    const heroComponent = format.components.find(
      (el) =>
        el.componentType.name.toLowerCase() ===
        MINT_PAGE_COMPONENT.HERO.toLowerCase()
    );
    if (heroComponent) {
      format.content = heroComponent.text;
    }

    const descriptionComponent = format.components.find(
      (el) =>
        el.componentType.name.toLowerCase() ===
        MINT_PAGE_COMPONENT.DESCRIPTION.toLowerCase()
    );
    if (descriptionComponent) {
      format.description = descriptionComponent.text;
    }

    const footerComponent = format.components.find(
      (el) =>
        el.componentType.name.toLowerCase() ===
        MINT_PAGE_COMPONENT.FOOTER.toLowerCase()
    );
    if (footerComponent) {
      format.facebook = footerComponent.componentSocials.find(
        (el) =>
          el.name &&
          el.name.toLowerCase() === ELEMENT_UPDATE.FACEBOOK.toLowerCase()
      )?.link;

      format.twitter = footerComponent.componentSocials.find(
        (el) =>
          el.name &&
          el.name.toLowerCase() === ELEMENT_UPDATE.TWITTER.toLowerCase()
      )?.link;

      format.instagram = footerComponent.componentSocials.find(
        (el) =>
          el.name &&
          el.name.toLowerCase() === ELEMENT_UPDATE.INSTAGRAM.toLowerCase()
      )?.link;

      format.telegram = footerComponent.componentSocials.find(
        (el) =>
          el.name &&
          el.name.toLowerCase() === ELEMENT_UPDATE.TELEGRAM.toLowerCase()
      )?.link;
    }

    return format;
  }

  async getTemplateTypes() {
    return this.get("/templateTypes");
  }

  async getMintPages(query = {}) {
    const mintPages = await this.get(`/mintPages`, query);
    if (!mintPages || !mintPages.length) {
      return [];
    }
    return mintPages.map((mintPage) => this.formatMintPage(mintPage));
  }

  async getMintPage(id) {
    const mintPage = await this.get(`/mintPages/${id}/publish`);
    if (!mintPage) return {};
    return this.formatMintPage(mintPage);
  }

  async getNextToken(contractAddress, networkName) {
    return this.get(`/smartContracts/getNextTokenInfo`, {
      contractAddress,
      networkName,
    });
  }

  async updateComponent(mintPageId, componentId, payload) {
    return this.put(
      `/mintPages/${mintPageId}/components/${componentId}`,
      payload
    );
  }

  // async verifyPaymentNFT(txId) {
  //   return this.post(`/multiChain/verifyPayment`, { txId, network: "xrp" });
  // }

  async verifyPaymentNFT(txId) {
    console.log("Verifying payment......txid", txId);
    try {
      const result = await this.post(`/multiChain/verifyPayment`, {
        txId,
        network: "xrp",
      });
      return result;
    } catch (error) {
      console.error("Error verifying payment..:", error);
      throw error; // Re-throw if you want to handle it elsewhere
    }
  }
}

export const mintPageService = new MintPageService();

import React from "react";
import LeftContent from "./components/LeftContent";
import RightContent from "./components/RightContent";

function TemplateSimple({
  mintPage = {},
  onMint,
  mintingData = {
    nextToken: null,
    isReady: false,
    isMintSubmitting: false,
  },
  amount,
  onUpdateAmount,
  contractData,
  smartContract,
  paymentCurrency,
  onChangePaymentCurrency,
}) {
  return (
    <div className="d-flex main-page-container">
      {/* left */}
      <LeftContent mintingData={mintingData} />

      {/* right */}
      <RightContent
        mintPage={mintPage}
        onMint={onMint}
        mintingData={mintingData}
        amount={amount}
        onUpdateAmount={onUpdateAmount}
        contractData={contractData}
        smartContract={smartContract}
        paymentCurrency={paymentCurrency}
        onChangePaymentCurrency={onChangePaymentCurrency}
      />
    </div>
  );
}

export default TemplateSimple;

import { Col, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { smartContractService } from "../../../services";

const IMAGE_URL_DEFAULT = "/token-default.png";

function CreatorInfo({ smartContract, mintPage }) {
  const [tokens, setTokens] = useState([]);

  const getTokens = async () => {
    const tokens = await smartContractService.getTokensBySmartContract(
      mintPage.smartContractId
    );
    if (!tokens) return null;
    const { tokens: tokenList = [] } = tokens;
    setTokens(tokenList);
  };

  useEffect(() => {
    getTokens();
  }, []);

  return (
    <div className="main-right-info-creator">
      <Row>
        <Col span={12}>
          <span className="main-right-info-title">Creator</span>
          <div className="d-flex align-items-center mt-3">
            <img
              src="/main/avatar.png"
              alt="mint page"
              className="main-page-avatar"
            />
            <Tooltip title={smartContract?.nameCreator}>
              <span className="main-right-info-content text-truncate">
                {smartContract?.nameCreator}
              </span>
            </Tooltip>
          </div>
        </Col>
        <Col span={12}>
          <span className="main-right-info-title">Collection</span>
          <div className="d-flex align-items-center mt-3">
            <img
              src={tokens ? tokens?.[0]?.image : IMAGE_URL_DEFAULT}
              alt="mint page"
              className="main-page-avatar"
            />
            <Tooltip title={smartContract?.name}>
              <span className="main-right-info-content text-truncate">
                {smartContract?.name}
              </span>
            </Tooltip>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CreatorInfo;

const CHAINS = {
  "flare-mainnet": {
    name: "Flare Mainnet",
    chainId: 14,
    icon: "/chains/flare.png",
    blockExplore: "https://flare-explorer.flare.network",
  },
  "songbird-mainnet": {
    name: "Songbird Mainnet",
    chainId: 19,
    icon: "/chains/songbird.png",
    blockExplore: "https://songbird-explorer.flare.network",
  },
  coston: {
    name: "Coston",
    chainId: 16,
    icon: "/chains/coston.png",
    blockExplore: "https://coston-explorer.flare.network",
  },
  coston2: {
    name: "Coston2",
    chainId: 114,
    icon: "/chains/coston2.png",
    blockExplore: "https://coston2-explorer.flare.network",
  },
  maticmum: {
    name: "Polygon Mumbai",
    chainId: 80001,
    icon: "/chains/matic.png",
    blockExplore: "https://mumbai.polygonscan.com",
  },
  matic: {
    name: "Polygon",
    chainId: 137,
    icon: "/chains/matic.png",
    blockExplore: "https://polygonscan.com",
  },
};

export { CHAINS };

import React from "react";
import { Button, Card, Col, Row, Flex } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import BigNumber from "bignumber.js";
const FreeMint = ({
  mintingData = {
    nextToken: null,
    isReady: false,
    isMintSubmitting: false,
  },
  onMint,
  amount,
  onUpdateAmount,
  contractData,
  mintPage,
  smartContract,
}) => {
  const { nextToken, isReady } = mintingData;

  const handleIncrement = () => {
    onUpdateAmount(amount + 1);
  };

  const handleDecrement = () => {
    if (amount > 0) {
      onUpdateAmount(amount - 1);
    }
  };

  return (
    <>
      <section className="free-mint-section">
        <div className="container">
          <Row className="justify-content-between align-items-center">
            <Col xs={24} lg={12} xl={12} className="text-center text-lg-start">
              {nextToken && (
                <img
                  className="img-fluid mint-img mb-5 mb-lg-0 pe-md-4"
                  src={nextToken.imageUrl}
                  alt="Free Mint"
                />
              )}
            </Col>
            <Col xs={24} lg={12} xl={10}>
              {/* <h2 className="main-heading mb-5">Freemint is Live</h2> */}
              <Card>
                <h3>{smartContract.name}</h3>

                <div className="d-flex justify-content-between align-items-center">
                  <Button onClick={handleDecrement}>
                    <MinusOutlined />
                  </Button>
                  <span className="number-count">{amount}</span>

                  <Button onClick={handleIncrement}>
                    <PlusOutlined />
                  </Button>
                </div>

                <p className="text-end mt-4">
                  Max Mint Amount : {contractData.maxTokensPerAddress}
                </p>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <p>Total</p>
                  <Flex gap={"0.3em"} align="center">
                    <strong>
                      {new BigNumber(contractData.mintPrice)
                        .multipliedBy(new BigNumber(amount))
                        .toString()}
                    </strong>
                    <img
                      src={smartContract.image}
                      alt={smartContract.network}
                      style={{ width: 17, height: 17 }}
                    />
                  </Flex>
                </div>
              </Card>

              <Button
                loading={mintingData.isMintSubmitting}
                className="btn btn-primary"
                onClick={(e) => onMint()}
                disabled={
                  !isReady ||
                  Number(amount) > Number(contractData.maxTokensPerAddress)
                }
              >
                Mint
              </Button>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default FreeMint;

import { Flex, message, Modal, Progress } from "antd";
import axios from "axios";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { API_URL } from "../../../config";
import { mintPageService } from "../../../services";
import MintNftPopup from "./MintNftPopup";

function VerifyPayment({ smartContract }, ref) {
  const [isVisible, setIsVisible] = useState(false);

  const refStartRound = useRef();
  const refEndRound = useRef();
  const [progress, setProgress] = useState(0);
  const refCurrentRound = useRef();

  const [isVerification, setIsVerification] = useState(false);

  const transactionId = useRef(null);
  const refMintNftPopup = useRef(null);
  const reCallVerification = useRef(0);

  const getPaymentVerification = async (round) => {
    return await axios.get(`/multiChain/getStatusVerify`, {
      params: { txId: transactionId.current, round },
      headers: {
        "Content-Type": "application/json",
      },
      baseURL: API_URL,
    });
  };

  const onProcessPaymentVerification = async () => {
    setTimeout(async () => {
      try {
        const response = await getPaymentVerification(refCurrentRound.current);
        const statusSuccess = response.data?.data?.status === "success";
        if (statusSuccess) {
          console.log("vao success");
          if (response.data?.data?.dataProof) {
            setIsVisible(false);
            setTimeout(() => {
              if (refMintNftPopup) {
                refMintNftPopup.current?.onShow(response.data?.data?.dataProof);
              }
            }, 300);
          } else {
            const steps =
              Number(refEndRound.current) - Number(refStartRound.current);
            const currStep =
              Number(refEndRound.current) - Number(refCurrentRound.current);

            setProgress(((steps - currStep) / steps) * 100);
            if (refCurrentRound.current < refEndRound.current) {
              refCurrentRound.current++;
              onProcessPaymentVerification();
            }
          }
        } else {
          onProcessPaymentVerification();
        }
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    }, 30000);
  };

  const onPaymentVerification = async () => {
    try {
      const response = await mintPageService.verifyPaymentNFT(
        transactionId.current
      );
      setIsVerification(true);
      const _startRound = response?.data?.startRound;
      const _endRound = response?.data?.endRound;
      refStartRound.current = _startRound;
      refEndRound.current = _endRound;
      refCurrentRound.current = _startRound;

      onProcessPaymentVerification();
    } catch (error) {
      // if validation fails, we will re-call 10 times
      if (reCallVerification.current <= 10) {
        reCallVerification.current++;
        setTimeout(() => {
          onPaymentVerification();
        }, 1000);
      } else {
        message.error("Failed to verify payment");
        console.error("Failed to verify payment: ", error);
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        onPaymentVerification();
      }, 2000);
    }
  }, [isVisible]);

  const onVerify = async ({ txId }) => {
    setIsVisible(true);
    transactionId.current = txId;
  };

  const onCancel = () => {
    setIsVisible(false);
  };

  useImperativeHandle(ref, () => ({
    onVerify,
    onCancel,
  }));

  return (
    <Modal open={isVisible} footer={false} centered closeIcon={null}>
      <div className="gap-4">
        <Flex align="center" vertical>
          <span style={{ fontSize: 24 }} className="fw-bold">
            Payment Verification
          </span>
          <span className="my-2">
            The verification process may take up to 5 minutes.
          </span>
        </Flex>
        {/* {isVerification && (
          <Progress
            percent={progress}
            showInfo={false}
            percentPosition={{ align: "center", type: "inner" }}
            size={["100%", 20]}
            strokeColor="#151517"
          />
        )} */}
        <Progress
          percent={progress}
          showInfo={false}
          percentPosition={{ align: "center", type: "inner" }}
          size={["100%", 20]}
        />
      </div>
      <MintNftPopup smartContract={smartContract} ref={refMintNftPopup} />
      {/* {!isVerification && (
        <Flex className="gap-2 justify-content-end">
          <Button className="w-25" onClick={() => setIsVisible(false)}>
            Cancel
          </Button>
          <Button
            className="w-25"
            style={{ background: "#151517", color: "white" }}
            onClick={onPaymentVerification}
          >
            Ok
          </Button>
        </Flex>
      )} */}
    </Modal>
  );
}

export default forwardRef(VerifyPayment);

import { Button, Col, Flex, message, Modal, Row, Tooltip } from "antd";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { connectContext } from "../../../connect/react/src/context/ConnectContext";
import { ConnectButton, WalletClient } from "../../../connect";
import { walletClientContext } from "../../../connect/react/src/context";
import PaymentMethodPopup from "./PaymentMethodPopup";

function ConnectWalletPopup({ smartContract }, ref) {
  const { adaptors, status, close } = useContext(connectContext);
  const { walletClient, setWalletClient } = useContext(walletClientContext);

  const [isVisible, setIsVisible] = useState(false);
  const [payableAmount, setPayableAmount] = useState("");
  const [paymentReference, setPaymentReference] = useState("");
  const [targetChainPaymentAddress, setTargetChainPaymentAddress] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  const refPaymentMethodPopup = useRef(null);

  const connectWallet = async () => {
    setWalletClient(new WalletClient(adaptors[2]));
  };

  const f = async () => {
    if (walletClient) {
      console.log('len trewn')
      await walletClient.init();
      const address = await walletClient.getAddress();
      if (address && refPaymentMethodPopup) {
        console.log('vao day roi')
        setTimeout(() => {
          refPaymentMethodPopup.current?.onShow({
            payableAmount: payableAmount,
            paymentReference: paymentReference,
            targetChainPaymentAddress: targetChainPaymentAddress,
          });
        }, 500);
      }
      if (!address) {
        const result = await walletClient.signIn();
        if (!result) {
          setWalletClient(null);
          return;
        }
        const address = await walletClient.getAddress();
        if (address) {
          setTimeout(() => {
            refPaymentMethodPopup.current?.onShow({
              payableAmount: payableAmount,
              paymentReference: paymentReference,
              targetChainPaymentAddress: targetChainPaymentAddress,
            });
          }, 500);
        }
      }
    }
    close();
  };

  useEffect(() => {
    f();
  }, [walletClient]);

  const onShow = ({
    payableAmount,
    paymentReference,
    targetChainPaymentAddress,
  }) => {
    setPayableAmount(payableAmount);
    setPaymentReference(paymentReference);
    setTargetChainPaymentAddress(targetChainPaymentAddress);
    setIsVisible(true);
  };

  const onCancel = () => {
    setIsVisible(false);
  };

  useImperativeHandle(ref, () => ({
    onShow,
    onCancel,
  }));

  const onOke = () => {
    setIsVisible(false);
  };

  const onConnectWallet = async () => {
    onCancel();
    setTimeout(async () => {
      await connectWallet();
    }, 500);
  };

  return (
    <Modal
      open={isVisible}
      onOk={onOke}
      onCancel={onCancel}
      footer={false}
      centered
      closeIcon={null}
    >
      <div className="gap-4">
        <Flex align="center" justify="space-between">
          <span style={{ fontSize: 24 }} className="fw-bold">
            Payment Method
          </span>
          <Button type="text" onClick={onCancel}>
            <img src="/common/ic_close.png" alt="" />
          </Button>
        </Flex>
        <div className="mt-4">
          <span style={{ fontSize: 16 }} className="fw-semibold">
            Details
          </span>
        </div>
        <Row gutter={16} className="mt-3">
          <Col span={12}>Payment amount</Col>
          <Col span={12} className="d-flex justify-content-end fw-semibold">
            {payableAmount} XRP
          </Col>
        </Row>
        <Row gutter={16} className="mt-3">
          <Col span={12}>Target Address</Col>
          <Col span={12} className="d-flex justify-content-end fw-semibold">
            {targetChainPaymentAddress}
          </Col>
        </Row>
        <Row gutter={16} className="mt-2">
          <Col span={12}>Payment reference</Col>
          <Col span={12} className="d-flex justify-content-end fw-semibold ">
            <Tooltip title={paymentReference}>
              <span className="text-truncate">{paymentReference}</span>
            </Tooltip>
          </Col>
        </Row>
      </div>
      <Button
        type="primary"
        className="bg-black fw-semibold mt-4"
        style={{ width: "100%" }}
        onClick={onConnectWallet}
        loading={isLoading}
      >
        Connect XRP Wallet
      </Button>
      <PaymentMethodPopup smartContract={smartContract} ref={refPaymentMethodPopup} />
    </Modal>
  );
}

export default forwardRef(ConnectWalletPopup);

import { Card, Col, Row } from "antd";
import React from "react";
import { DEFAULT_TEXT_DESCRIPTION, MINT_PAGE_COMPONENT } from "../../constants";

const JoinNft = ({ mintPage }) => {
  const descriptionComponent = mintPage.components.find(
    (component) =>
      component.componentType.name.toLowerCase() ===
      MINT_PAGE_COMPONENT.DESCRIPTION.toLowerCase()
  );

  const text = descriptionComponent.text || DEFAULT_TEXT_DESCRIPTION;

  return (
    <>
      {text && (
        <section className="join-section join-section-two join-section-three">
          <div className="container">
            <Row align="center">
              <Col xs={24} lg={20}>
                <Card>
                  <h2 className="main-heading">{text}</h2>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      )}
    </>
  );
};

export default JoinNft;

import { Col, Row } from "antd";
import React, { useState, useEffect } from "react";
import { smartContractService } from "../../services";

const Collection = ({ mintPage }) => {
  const [tokens, setTokens] = useState([]);

  // const collectionData = [
  //   { id: 1, img: "/template/collection/1.png" },
  //   { id: 2, img: "/template/collection/2.png" },
  //   { id: 3, img: "/template/collection/3.png" },
  //   { id: 4, img: "/template/collection/4.png" },
  //   { id: 5, img: "/template/collection/5.png" },
  //   { id: 6, img: "/template/collection/6.png" },
  //   { id: 7, img: "/template/collection/7.png" },
  //   { id: 8, img: "/template/collection/8.png" },
  //   { id: 9, img: "/template/collection/9.png" },
  // ];
  const getTokens = async () => {
    const tokens = await smartContractService.getTokensBySmartContract(
      mintPage.smartContractId
    );
    if (!tokens) return null;
    const { tokens: tokenList = [] } = tokens;
    setTokens(tokenList);
  };

  useEffect(() => {
    getTokens();
  }, []);

  return (
    <>
      <section className="collection-section">
        <div className="container">
          <Row align="center">
            <Col xs={24} md={20}>
              <h2 className="main-heading text-center mb-5 pb-3">
                Collections
              </h2>

              <Row className="mb-md-5">
                {tokens.map((item) => (
                  <Col xs={12} sm={8} key={item.id}>
                    <img
                      className="img-fluid w-100 mb-3 px-2"
                      src={item.image}
                      alt="1"
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Collection;

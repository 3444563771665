import { Col, Row } from "antd";
import React from "react";
import { DEFAULT_BRAND_NAME, MINT_PAGE_COMPONENT } from "../../constants";

const Footer = ({ mintPage }) => {
  const footerComponent = mintPage.components.find(
    (component) =>
      component.componentType.name.toLowerCase() ===
      MINT_PAGE_COMPONENT.FOOTER.toLocaleLowerCase()
  );

  const socialData = [
    { id: 1, icon: "icon-twitter", name: "twitter" },
    { id: 2, icon: "icon-insta", name: "instagram" },
    { id: 3, icon: "icon-facebook", name: "facebook" },
    { id: 4, icon: "icon-telegram", name: "telegram" },
  ];

  const data = socialData
    .map((item) => {
      const socialData = footerComponent.componentSocials;
      if (socialData.length) {
        const social = socialData.find(
          (social) => social.name && social.name.toLowerCase() === item.name
        );
        if (social) {
          return {
            ...item,
            link: social.link,
          };
        }
      }
      return item;
    })
    .filter((item) => item.link);

  return (
    <>
      <footer className="footer-two">
        <div className="container">
          <Row>
            <Col xs={24} className="text-center">
              <a
                href="true"
                className="navbar-brand mb-4"
                onClick={(e) => e.preventDefault()}
              >
                <p className="brand-stabillo">
                  {mintPage.brandName || DEFAULT_BRAND_NAME}
                </p>
              </a>
              <ul>
                {data.map((item) => (
                  <li>
                    <a href={item.link} key={item.id}>
                      <i className={`icon ${item.icon}`}></i>
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import { Col, Row } from "antd";
import React from "react";
import { DEFAULT_TITLE_HERO, MINT_PAGE_COMPONENT } from "../../constants";

const Hero = ({ mintPage = {} }) => {
  const heroComponent = mintPage.components.find(
    (component) =>
      component.componentType.name.toLowerCase() ===
      MINT_PAGE_COMPONENT.HERO.toLowerCase()
  );

  const componentImages =
    heroComponent.componentImageUpdated || heroComponent.componentImages || [];
  const text = heroComponent.text || DEFAULT_TITLE_HERO;

  const generateTitle = () => {
    return <h1 className="main-heading mt-md-5 pt-md-3 pb-5">{text}</h1>;
  };

  const generateImages = () => {
    const isHaveImage = componentImages && componentImages.length > 0;
    const heroSmallImg = isHaveImage
      ? componentImages[0]?.url
      : "/template/home-three/hero-sm.png";
    const heroImg = isHaveImage
      ? componentImages[1]?.url
      : "/template/home-three/hero.png";

    return (
      <div className="position-raltive">
        <div className="container-hero-large">
          {heroImg && <img src={heroImg} alt="" />}
        </div>
        <div className="container-hero-small">
          {heroSmallImg && <img src={heroSmallImg} alt="" />}
        </div>
      </div>
    );
  };
  return (
    <>
      <section className="hero-section hero-two-section hero-three-section pt-3 pb-0">
        <div className="container">
          <Row>
            <Col xs={24} lg={12}>
              {generateTitle()}
            </Col>
            <Col xs={24} lg={12} className="text-center text-md-end">
              {generateImages()}
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Hero;

import React, { useEffect, useState } from "react";
import Header from "../components/home/header";
import Footer from "../components/home/footer";
import Rating from "../components/home/rating";
import Hero from "../components/home/hero";
import JoinNft from "../components/home/JoinNft";
import Collection from "../components/home/collection";
import FreeMint from "../components/home/FreeMint";

const TemplatePurp = ({
  mintPage = {},
  onMint,
  mintingData,
  amount,
  onUpdateAmount,
  contractData,
  smartContract,
}) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleSwitchChange = () => {
    setIsDarkMode(!isDarkMode);
  };

  const containerStyles = {
    backgroundImage: isDarkMode
      ? 'url("/dark-bg.svg")'
      : 'url("/light-bg.svg")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <>
      <div
        style={containerStyles}
        className={`theme-switcher ${isDarkMode ? "dark-mode" : "light-mode"}`}
      >
        <Header
          mintPage={mintPage}
          isDarkMode={isDarkMode}
          onSwitchChange={handleSwitchChange}
        />

        <Hero mintPage={mintPage} />
        <Rating mintPage={mintPage} contractData={contractData} />
        <FreeMint
          onMint={onMint}
          mintingData={mintingData}
          amount={amount}
          contractData={contractData}
          onUpdateAmount={onUpdateAmount}
          mintPage={mintPage}
          smartContract={smartContract}
        />
        <Collection mintPage={mintPage} />
        <JoinNft mintPage={mintPage} />
        <Footer mintPage={mintPage} />
      </div>
    </>
  );
};

export default TemplatePurp;

import TemplateCalm from "./template-calm";
import TemplatePurp from "./template-purp";
import TemplateStabillo from "./template-stabillo";
import { TEMPLATE } from "../constants";
import {
  useSimulateContract,
  useAccount,
  useWaitForTransactionReceipt,
  useWriteContract,
  useReadContract,
} from "wagmi";
import { message } from "antd";
import { createContext, useEffect } from "react";
import { contract } from "../contract";
import { parseEther, formatEther } from "ethers";
import { useState } from "react";
import BigNumber from "bignumber.js";
import TemplateSimple from "./TemplateSimple";
import { contractMultichain } from "../contract/contractByteMultichainAbi";

export const TriggerContext = createContext({});

const MintPage = ({
  mintPage,
  templates,
  smartContract,
  mintingData,
  onMintSuccess,
  onMinting,
}) => {
  const { isConnected, address } = useAccount();
  const [amount, setAmount] = useState(0);
  const [paymentCurrency, setPaymentCurrency] = useState("xrp");
  const [contractData, setContractData] = useState({
    totalMinted: "0",
    totalSupply: "0",
    mintPrice: "0",
    owners: "0",
  });
  const [trigger, setTrigger] = useState(false);

  const template = templates?.find(
    (template) => template.id === mintPage.templateTypeId
  );
  const abi = paymentCurrency === "xrp" ? contractMultichain.abi : contract.abi;

  const contractConfig = {
    address: smartContract?.contractAddress,
    abi,
  };

  const {
    data: totalMinted,
    refetch: refetchTotalMinted,
    isSuccess: isGotTotalMinted,
  } = useReadContract({
    ...contractConfig,
    functionName: "getTotalMinted",
  });

  const {
    data: totalSupply,
    refetch: refetchTotalSupply,
    isSuccess: isGotTotalSupply,
  } = useReadContract({
    ...contractConfig,
    functionName: "totalSupply",
  });

  console.log('refetchTotalMinted', refetchTotalMinted)

  const { data: mintPrice, isSuccess: isGotMintPrice } = useReadContract({
    ...contractConfig,
    functionName: "getMintingPrice",
  });

  const { data: maxTokensPerAddress, isSuccess: isGotMaxTokensPerAddress } =
    useReadContract({
      ...contractConfig,
      functionName: "getMaxTokensPerAddress",
    });

  const { data } = useSimulateContract({
    ...contractConfig,
    functionName: "mint",
    args: [address, amount],
    account: address,
    value: parseEther(
      new BigNumber(contractData.mintPrice)
        .multipliedBy(new BigNumber(amount))
        .toString()
    ),
    onError(error) {
      console.log("Error-inside-hook", error);
    },
  });

  const {
    data: mintData,
    writeContract: mint,
    isPending: isMintLoading,
    isSuccess: isMintStarted,
    error: mintError,
  } = useWriteContract();

  const { isSuccess: txSuccess } = useWaitForTransactionReceipt({
    hash: mintData,
  });

  const mintToken = async () => {
    mint?.({
      ...data?.request,
    });
  };

  const onChangePaymentCurrency = (value) => {
    setPaymentCurrency(value);
  };

  const getTemplateUI = () => {
    if (!templates || !mintPage) return null;
    if (!template) return null;

    if (template?.name === TEMPLATE.PURP) {
      return (
        <TemplatePurp
          mintPage={mintPage}
          onMint={mintToken}
          mintingData={mintingData}
          amount={amount}
          onUpdateAmount={setAmount}
          contractData={contractData}
          smartContract={smartContract}
        />
      );
    }

    if (template?.name === TEMPLATE.STABILLO) {
      return (
        <TemplateStabillo
          mintPage={mintPage}
          onMint={mintToken}
          mintingData={mintingData}
          amount={amount}
          onUpdateAmount={setAmount}
          contractData={contractData}
          smartContract={smartContract}
        />
      );
    }

    if (template?.name === TEMPLATE.CALM) {
      return (
        <TemplateCalm
          mintPage={mintPage}
          onMint={mintToken}
          mintingData={mintingData}
          amount={amount}
          onUpdateAmount={setAmount}
          contractData={contractData}
          smartContract={smartContract}
        />
      );
    }

    if (template?.name === TEMPLATE.SIMPLE) {
      return (
        <TemplateSimple
          mintPage={mintPage}
          onMint={mintToken}
          mintingData={mintingData}
          amount={amount}
          onUpdateAmount={setAmount}
          contractData={contractData}
          smartContract={smartContract}
          paymentCurrency={paymentCurrency}
          onChangePaymentCurrency={onChangePaymentCurrency}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    if (
      isGotMintPrice ||
      isGotTotalSupply ||
      isGotTotalMinted ||
      isGotMaxTokensPerAddress
    ) {
      setContractData({
        ...contractData,
        totalMinted: totalMinted ? totalMinted.toString() : "0",
        totalSupply: totalSupply ? totalSupply.toString() : "0",
        mintPrice: mintPrice ? formatEther(mintPrice) : "0",
        maxTokensPerAddress: maxTokensPerAddress
          ? maxTokensPerAddress.toString()
          : "0",
      });
    }

    if (txSuccess) {
      onMintSuccess();
      message.success("Minted successfully");
    }

    if (mintError) {
      console.log("mintError", mintError);
      message.error("Minting failed");
    }

    if (isMintLoading && !isMintStarted) {
      onMinting(true);
    }
  }, [
    isConnected,
    txSuccess,
    mintError,
    isGotMintPrice,
    isGotTotalSupply,
    isGotTotalMinted,
    isGotMaxTokensPerAddress,
    isMintLoading,
    isMintStarted,
  ]);

  return (
    <TriggerContext.Provider
      value={{
        refetchTotalMinted,
        refetchTotalSupply,
        setTrigger,
      }}
    >
      <section className="studio-section template-section">
        {getTemplateUI()}
      </section>
    </TriggerContext.Provider>
  );
};

export default MintPage;

import { Button, Flex, message, Modal } from "antd";
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import { BrowserProvider, ContractFactory, JsonRpcSigner } from "ethers";
import { contractMultichain } from "../../../contract/contractByteMultichainAbi";
import { useWalletClient } from "wagmi";
import { TriggerContext } from "../../mint-page";

function MintNftPopup({ smartContract }, ref) {
  const { refetchTotalMinted, refetchTotalSupply } = useContext(TriggerContext);
  const [isVisible, setIsVisible] = useState(false);
  const [isMint, setIsMint] = useState(false);
  const [proof, setProof] = useState("");

  const onShow = (proof) => {
    setProof(proof);
    setIsVisible(true);
  };

  const onCancel = () => {
    setIsVisible(false);
  };

  useImperativeHandle(ref, () => ({
    onShow,
    onCancel,
  }));

  const onOke = () => {
    setIsVisible(false);
  };

  const { data: walletClient, isLoading } = useWalletClient();

  function walletClientToSigner(walletClient) {
    const { account, chain, transport } = walletClient;
    const network = {
      chainId: chain.id,
      name: chain.name,
      ensAddress: chain.contracts?.ensRegistry?.address,
    };
    const provider = new BrowserProvider(transport, network);
    const signer = new JsonRpcSigner(provider, account.address);
    return signer;
  }

  const onMintNft = async () => {
    try {
      const atrivAddress = smartContract?.contractAddress;
      const signer = walletClientToSigner(walletClient);
      const contractDeploy = new ContractFactory(
        contractMultichain.abi,
        contractMultichain.bytecode,
        signer
      );
      setIsMint(true);
      const atriv = await contractDeploy.attach(atrivAddress);
      // Call the mintMultiChain function with the prepared Payment object
      const mintTx = await atriv.mintMultiChain(proof, {
        gasLimit: 3000000, // Set a manual gas limit (adjust as needed)
      });
      // Wait for the mint transaction to be mined
      const mintReceipt = await mintTx.wait();

      // reload page
      // await refetchTotalMinted();
      // await refetchTotalSupply();
      // setTrigger((prev) => !prev);
      window.location.reload();
      setIsMint(false);
      onCancel();
      // Check if the transaction was successful
      if (mintReceipt.status === 1) {
        console.log("Minting was successful and returned true.");
        message.success("Minting was successful");
      } else {
        console.log("Minting failed.");
        message.error("Minting failed");
      }
    } catch (error) {
      setIsMint(false);
      message.error("Minting failed");
      console.log("Minting failed.", error);
    }
  };

  return (
    <Modal
      open={isVisible}
      onOk={onOke}
      // onCancel={onCancel}
      footer={false}
      centered
      closeIcon={null}
    >
      <Flex align="center" justify="center" vertical>
        <img src="/reserve-success.png" alt="" />
        <span>1 NFT have been reserved for you</span>
      </Flex>
      <Button
        type="primary"
        className="bg-black fw-semibold mt-4"
        style={{ width: "100%" }}
        onClick={onMintNft}
        loading={isMint}
      >
        Mint
      </Button>
    </Modal>
  );
}

export default forwardRef(MintNftPopup);

const TEMPLATE_MODE = {
  LIGHT: "Light",
  DARK: "Dark",
};

const TEMPLATE = {
  PURP: "Purp",
  STABILLO: "Stabillo",
  CALM: "Calm",
  SIMPLE: "Simple",
  INKWELL: "Inkwell",
};

const DEFAULT_TITLE_HERO =
  "Unlock Your Creativity Collect The Best NFTs Digital Art";

const DEFAULT_TEXT_DESCRIPTION =
  "Join the NFT Revolution and Showcase Your Unique Artistry";

const DEFAULT_BRAND_NAME = "NFMT";
const DEFAULT_TWITTER = "twitter.com/user";
const DEFAULT_TELEGRAM = "telegram.me/user";
const DEFAULT_INSTAGRAM = "instagram.com/user";
const DEFAULT_FACEBOOK = "facebook.com/user";

const ELEMENT_UPDATE = {
  BRAND_NAME: "brandName",
  CONTENT: "content",
  DESCRIPTION: "description",
  FACEBOOK: "facebook",
  INSTAGRAM: "instagram",
  TWITTER: "twitter",
  TELEGRAM: "telegram",
  HERO_IMAGE: "heroImage",
  SUBDOMAIN: "subdomain",
  BACKGROUND_COLOR: "backgroundColor",
};

const MINT_PAGE_COMPONENT = {
  LOGO: "Logo",
  HERO: "Hero",
  MINT: "Mint",
  STATIC: "Static",
  COLLECTION: "Collection",
  DESCRIPTION: "Description",
  FOOTER: "Footer",
};

const WALLET_CONNECT_PROJECT_ID = "1444614941892d539b7c5046af445f43";

export {
  TEMPLATE_MODE,
  TEMPLATE,
  DEFAULT_TITLE_HERO,
  DEFAULT_TEXT_DESCRIPTION,
  DEFAULT_BRAND_NAME,
  ELEMENT_UPDATE,
  MINT_PAGE_COMPONENT,
  DEFAULT_TWITTER,
  DEFAULT_TELEGRAM,
  DEFAULT_INSTAGRAM,
  DEFAULT_FACEBOOK,
  WALLET_CONNECT_PROJECT_ID,
};

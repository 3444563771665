import { API_URL } from "../config";

class BaseService {
  baseUrl = "";
  token = "";

  constructor() {
    this.baseUrl = API_URL;
    const token = localStorage.getItem("token");
    if (token) {
      this.token = token;
    }
  }

  setToken(token) {
    this.token = token;
  }

  async request(url, method, body, header) {
    const updateHeader = {
      "Content-Type": "application/json",
      ...header,
      Authorization: `Bearer ${this.token}`,
    };
    const resp = await fetch(`${this.baseUrl}${url}`, {
      method,
      body: body ? JSON.stringify(body) : null,
      headers: updateHeader,
    });

    const json = await resp.json();
    if (!resp.ok) {
      throw json;
    }
    return json;
  }

  async get(url, query, header) {
    if (query) {
      url = `${url}?${new URLSearchParams(query).toString()}`;
    }
    try {
      const resp = await this.request(url, "GET", null, header);
      return resp;
    } catch (error) {
      console.error(error);
    }
  }

  post(url, body, header) {
    return this.request(url, "POST", body, header);
  }

  put(url, body, header) {
    return this.request(url, "PUT", body, header);
  }

  delete(url, body, header) {
    return this.request(url, "DELETE", body, header);
  }

  patch(url, body, header) {
    return this.request(url, "PATCH", body, header);
  }

  async upload(url, body, header) {
    const updateHeader = {
      ...header,
      Authorization: `Bearer ${this.token}`,
    };

    const resp = await fetch(`${this.baseUrl}${url}`, {
      method: "POST",
      body,
      headers: updateHeader,
    });

    const json = await resp.json();

    if (!resp.ok) {
      throw json;
    }
    return json;
  }

  generatePropDrawerUploadProps(props) {
    const updateHeader = {
      ...props.headers,
      Authorization: `Bearer ${this.token}`,
    };
    return {
      action: `${this.baseUrl}/upload/image`,
      ...props,
      headers: updateHeader,
    };
  }
}

export { BaseService };

import React from "react";
const IMAGE_URL_DEFAULT = "/token-default.png";

function LeftContent({mintingData}) {
  const { nextToken } = mintingData;
  const screenHeight = window.innerHeight;

  return (
    <div style={{ width: "60%" }}>
      <img
        src={nextToken ? nextToken.imageUrl : IMAGE_URL_DEFAULT}
        alt="mint page"
        className="main-background-image"
        style={{ height: screenHeight }}
      />
      <img
        src={nextToken ? nextToken.imageUrl : IMAGE_URL_DEFAULT}
        alt="mint page"
        className="main-image"
      />
    </div>
  );
}

export default LeftContent;

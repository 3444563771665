import React, { useState } from "react";
import { Button, Card, Col, Row, Flex } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import BigNumber from "bignumber.js";

const FreeMint = ({
  mintingData = {
    nextToken: null,
    isReady: false,
    isMintLoading: false,
  },
  onMint,
  amount,
  onUpdateAmount,
  contractData,
  mintPage,
  smartContract,
}) => {
  const { nextToken, isReady } = mintingData;

  const handleIncrement = () => {
    onUpdateAmount(amount + 1);
  };

  const handleDecrement = () => {
    if (amount > 0) {
      onUpdateAmount(amount - 1);
    }
  };

  return (
    <>
      <section className="free-mint-section free-mint-two-section free-mint-three-section">
        <div className="container">
          {/* <h2 className="main-heading text-center mb-5 pb-4">
            Freemint is Live
          </h2> */}

          <div className="fint-card">
            <Row className="justify-content-between align-items-center">
              <Col xs={24} lg={12} xl={10}>
                <Card>
                  <h3 className="mb-2">{smartContract.name}</h3>

                  <div className="d-flex justify-content-between align-items-center">
                    <Button onClick={handleDecrement}>
                      <MinusOutlined />
                    </Button>
                    <span className="number-count">{amount}</span>

                    <Button onClick={handleIncrement}>
                      <PlusOutlined />
                    </Button>
                  </div>

                  <p className="text-end mt-4">
                    Max Mint Amount : {contractData.maxTokensPerAddress}
                  </p>
                  <hr />
                  <div className="d-flex justify-content-between align-items-center">
                    <p>Total</p>
                    <Flex gap={"0.3em"} align="center">
                      <strong>
                        {new BigNumber(contractData.mintPrice)
                          .multipliedBy(new BigNumber(amount))
                          .toString()}
                      </strong>
                      <img
                        src={smartContract.image}
                        alt={smartContract.network}
                        style={{ width: 17, height: 17 }}
                      />
                    </Flex>
                  </div>
                </Card>
                <div className="text-center">
                  <Button
                    loading={mintingData.isMintSubmitting}
                    className="btn btn-light"
                    onClick={(e) => onMint()}
                    disabled={
                      !isReady ||
                      Number(amount) > Number(contractData.maxTokensPerAddress)
                    }
                  >
                    Mint
                  </Button>
                </div>
              </Col>

              <Col
                xs={24}
                lg={12}
                xl={12}
                className="text-center text-lg-start"
              >
                {nextToken && (
                  <img
                    className="img-fluid mint-img mt-5 mt-lg-0"
                    src={nextToken.imageUrl}
                    alt="Free Mint"
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};

export default FreeMint;
